import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi sparkar igång säsongen med chattappen som rekommenderas av Edward Snowden och Elon Musk. Vi kikar även på Linux gaming 2021, RISC-V och Linux på M1, Pop!_OS och mycket mer. Häng med och lyssna in på säsongens första avsnitt!`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Signal växer i popularitet: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/opensource/comments/kuyxr8/open_source_signal_becomes_the_top_free_app_on/"
        }}>{`https://www.reddit.com/r/opensource/comments/kuyxr8/open_source_signal_becomes_the_top_free_app_on/`}</a></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`Linux gaming 2021: `}<a parentName="li" {...{
          "href": "https://www.gamingonlinux.com/2021/01/what-we-expect-to-come-from-valve-to-help-linux-gaming-in-2021"
        }}>{`https://www.gamingonlinux.com/2021/01/what-we-expect-to-come-from-valve-to-help-linux-gaming-in-2021`}</a></li>
      <li parentName="ul">{`(Typ köpbar) RISC-V dator: `}<a parentName="li" {...{
          "href": "https://beaglev.seeed.cc/"
        }}>{`https://beaglev.seeed.cc/`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/kwgetx/_/"
        }}>{`https://www.reddit.com/r/linux/comments/kwgetx/_/`}</a></li>
      <li parentName="ul">{`Torvalds "crazy old man": `}<a parentName="li" {...{
          "href": "https://www.techradar.com/news/linus-torvalds-slamshimself-over-latest-linux-build"
        }}>{`https://www.techradar.com/news/linus-torvalds-slamshimself-over-latest-linux-build`}</a></li>
      <li parentName="ul">{`Wine 6.0: `}<a parentName="li" {...{
          "href": "https://www.winehq.org/news/2021011401"
        }}>{`https://www.winehq.org/news/2021011401`}</a></li>
      <li parentName="ul">{`Arch indexerar man pages (tack Christer): `}<a parentName="li" {...{
          "href": "https://archlinux.org/news/manual-pages-indexing-service"
        }}>{`https://archlinux.org/news/manual-pages-indexing-service`}</a>{` `}<a parentName="li" {...{
          "href": "https://man.archlinux.org"
        }}>{`https://man.archlinux.org`}</a>{` `}</li>
      <li parentName="ul">{`PineTime: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/kpcjb8/pinetime"
        }}>{`https://www.reddit.com/r/linux/comments/kpcjb8/pinetime`}</a></li>
      <li parentName="ul">{`Linux nu på M1 mac: `}<a parentName="li" {...{
          "href": "https://twitter.com/cmwdotme/status/1351838924621099008?s=21"
        }}>{`https://twitter.com/cmwdotme/status/1351838924621099008?s=21`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Chromium tappar funktioner: `}<a parentName="li" {...{
          "href": "https://blog.chromium.org/2021/01/limiting-private-api-availability-in.html"
        }}>{`https://blog.chromium.org/2021/01/limiting-private-api-availability-in.html`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Pop!_OS: `}<a parentName="li" {...{
          "href": "https://pop.system76.com/"
        }}>{`https://pop.system76.com/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Alex PinePhone (feat. Samuel) `}<ul parentName="li">
          <li parentName="ul">{`Samuels Telefoniäventyr: `}<a parentName="li" {...{
              "href": "https://tofubitar.gitlab.io/2021/01/16/elks-levererar/"
            }}>{`https://tofubitar.gitlab.io/2021/01/16/elks-levererar/`}</a></li>
        </ul></li>
      <li parentName="ul">{`(Smyguppdateringar Rust)`}</li>
      <li parentName="ul">{`Elefanten i rummet: Chatten`}<ul parentName="li">
          <li parentName="ul">{`Matrix-kanal: `}<a parentName="li" {...{
              "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
            }}>{`https://matrix.to/#/%23TrevligMjukvara:matrix.org`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Youtube-klipp: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ`}</a></li>
      <li parentName="ul">{`Tack till alla superhjältar!`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" by Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      